
import { defineComponent, ref } from 'vue';
import { dayjsFormat } from '@/methods/dayjs';
import { apartment } from '@/data';
import { GetRoomPaymentList } from './types/records.d';

export default defineComponent({
    name: 'property-bread-crumb',
    props: {
        UUID: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const mapWordOfStatus = {
            0: WordList.ProperAllTextPending,
            1: WordList.PropertyAllTextCompleted,
            2: WordList.PropertyAllTextFailed
        };
        const formData = ref({
            PersonalAccountUUID: props.UUID,
            BeginTime: '',
            EndTime: '',
            row: 10,
            page: 1
        });
        // 表格数据
        const tableData = ref<GetRoomPaymentList>({
            total: 0,
            row: []
        });
        // 表格属性
        const column = [
            { label: WordList.TabelMessageBoxCreateTime, prop: 'CreateTime' },
            { label: WordList.OrderOrderNumber, prop: 'OrderNumber' },
            { label: WordList.ProductName, prop: 'ProductName' },
            { label: WordList.AzerbaijanAmount, prop: 'Amount' },
            { label: WordList.MulListUpdateListTanleStatus, prop: 'Status' },
            { label: WordList.Payor, prop: 'Payor' }
        ];

        function close() {
            emit('close');
        }
        function getRoomPaymentListApi() {
            const params = { ...formData.value };
            params.BeginTime = params.BeginTime ? dayjsFormat(params.BeginTime, 'YYYY-MM-DD HH:mm:ss') : '';
            params.EndTime = params.EndTime ? dayjsFormat(params.EndTime, 'YYYY-MM-DD HH:mm:ss') : '';
            apartment.getRoomPaymentList(params, (res: GetRoomPaymentList) => {
                tableData.value.total = res.total;
                tableData.value.row = res.row;
            });
        }
        function searchList() {
            formData.value.page = 1;
            getRoomPaymentListApi();
        }

        // 日期格式化
        function formatDate(EffectDate: string, ExpireDate: string, IsAutoSend: boolean) {
            if (IsAutoSend) {
                return dayjsFormat(EffectDate, 'MMMM, YYYY');
            }
            return `${dayjsFormat(EffectDate, 'MMMM DD, YYYY')} - ${dayjsFormat(ExpireDate, 'MMMM DD, YYYY')}`;
        }
        const initData = () => {
            getRoomPaymentListApi();
        };
        initData();
        return {
            formData,
            column,
            tableData,
            searchList,
            getRoomPaymentListApi,
            mapWordOfStatus,
            formatDate,
            close
        };
    }
});
