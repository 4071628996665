import dayjs from 'dayjs';

async function setLang() {
    if (Lang === 'zh-cn') {
        await import('dayjs/locale/zh-cn');
    } else if (Lang === 'korean') {
        await import('dayjs/locale/ko');
    } else if (Lang === 'turkish') {
        await import('dayjs/locale/tr');
    } else if (Lang === 'ja') {
        await import('dayjs/locale/ja');
    } else if (Lang === 'pl') {
        await import('dayjs/locale/pl');
    } else if (Lang === 'ru') {
        await import('dayjs/locale/ru');
    } else if (Lang === 'bs') {
        await import('dayjs/locale/bs');
    } else if (Lang === 'da') {
        await import('dayjs/locale/da');
    } else if (Lang === 'vi') {
        await import('dayjs/locale/vi');
    } else if (Lang === 'ar') {
        await import('dayjs/locale/ar');
    } else if (Lang === 'he') {
        await import('dayjs/locale/he');
    } else if (Lang === 'az') {
        await import('dayjs/locale/az');
    } else {
        await import('dayjs/locale/en');
    }
    dayjs.locale(Lang);
}
setLang();

const format = (time: Date | string | null, type = 'YYYY-MM-DD HH:mm:ss') => {
    if (time === null) return '';
    if (typeof time === 'string') return time;
    return dayjs(time).format(type);
};

/**
 * 通过dayjs转换时间格式
 * @param time 原始时间
 * @param type 要转换的格式
 * @param originType 原始的时间格式，需要兼容日月年格式时必须传入
 * @returns 转换后的时间
 */
const dayjsFormat = (time: Date | string, type = 'YYYY-MM-DD HH:mm:ss', originType?: string) => {
    if (time === null || time === undefined) {
        if (process.env.NODE_ENV === 'development') {
            throw new TypeError('Invalid time');
        }
        console.error('Invalid time');
        return time;
    }

    let parsedTime = time;

    // dayjs无法处理日月年格式 (底层的 new Date 不兼容日月年)，需要再转换一下格式
    if (originType && originType.includes('DD-MM-YYYY')) {
        if (typeof time === 'string') {
            parsedTime = dealWithDMY(time);
        } else if (time instanceof Date && time.toString() === 'Invalid Date') {
            if (process.env.NODE_ENV === 'development') {
                throw new TypeError('Invalid Date in DD-MM-YYYY format');
            }
            console.error('Invalid Date in DD-MM-YYYY format');
            return time.toString();
        }
    }
    let dayjsInstance = dayjs(parsedTime);
    // 12小时制需要做额外处理，否则火狐上运行结果会变成Invalid Date（关联bug：52037）
    if (parsedTime.toString().endsWith('AM') || parsedTime.toString().endsWith('PM')) {
        if (originType && originType.includes('DD-MM-YYYY')) {
            dayjsInstance = dayjs(parsedTime.toString(), 'YYYY-MM-DD HH:mm:ss A');
        } else {
            dayjsInstance = dayjs(parsedTime.toString(), originType);
        }
    }
    return dayjsInstance.format(type);
};

const getCustomizeForm = (customizeForm: string) => {
    let time1 = 1;
    let time2 = 2;
    if (Number(customizeForm) % 2 === 0) {
        time1 = 1;
    } else {
        time1 = 2;
    }
    time2 = Number(customizeForm) - time1;

    let formatTime = '';
    let formatDate = '';
    if (time1 === 1) {
        formatTime = 'hh:mm:ss A';
    } else {
        formatTime = 'HH:mm:ss';
    }

    if (time2 === 1) {
        formatDate = 'YYYY-MM-DD';
    } else if (time2 === 3) {
        formatDate = 'MM-DD-YYYY';
    } else {
        formatDate = 'DD-MM-YYYY';
    }

    return `${formatDate} ${formatTime}`;
};

/**
 * @name 因dayjs无法处理日月年格式，增加此函数（关联bug：51247）
 * @param origin 原时间字符串
 * @returns 格式转换结果
 */
function dealWithDMY(origin: string) {
    if (typeof origin === 'string') {
        const splitted = origin.split(' ');
        return `${splitted[0].split('-').reverse().join('-')} ${splitted[1]}${splitted[2] ? ` ${splitted[2]}` : ''}`;
    }
    else return origin;
}

/**
 * @name 获取传入时间的后几个月时间
 * @param expireTime 计算时间，格式'2020-01-01 11:11:11'
 * @param count 月份
 */
const getNextMonthTime = (expireTime: string, count: number) => {
    const initDate = expireTime.split(' ')[0];
    const initTime = expireTime.split(' ')[1];
    let year = Number(initDate.split('-')[0]);
    let month = Number(initDate.split('-')[1]);
    let date = Number(initDate.split('-')[2]);
    if (month + count > 12) {
        month = month + count - 12;
        year += 1;
        if (month === 2 && date > 28) {
            date = 28;
        }
    } else {
        month += count;
    }
    return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date} ${initTime}`;
};

/**
 * @name 对比当前时间，决定下次过期时间
 * @param initExpireTime 初始过期时间
 * @param count 月份
 */
const getNextExpireTime = (initExpireTime: string, count: number) => {
    const nowTime = new Date();
    const expireTime = new Date(initExpireTime).getTime();
    // 对比当前时间和过期时间，已过期则采用当前时间计算
    const time = (nowTime.getTime() > expireTime || initExpireTime === '--') ? format(nowTime) : initExpireTime;
    return getNextMonthTime(time, count);
};

/**
 * @name 比较两个时间大小
 * @param startTime 第一个时间
 * @param endTime 第二个时间
 * @return boolean endTime > startTime: true
 */
const compareTime = (startTime: Date | string, endTime: Date | string) => {
    let resStartTime = startTime;
    let resEndTime = endTime;
    if (typeof resStartTime === 'string') {
        resStartTime = new Date(resStartTime);
    }
    if (typeof resEndTime === 'string') {
        resEndTime = new Date(resEndTime);
    }
    return resEndTime.getTime() > resStartTime.getTime();
};

export default null;
export {
    format,
    dayjsFormat,
    dealWithDMY,
    getCustomizeForm,
    getNextMonthTime,
    getNextExpireTime,
    compareTime
};
